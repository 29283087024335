
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "OrderLibrary",
  setup() {
    const store = useStore();
    const orderLibrary = computed(() => store.state.main.orderLibrary);
    return {
      orderLibrary,
    };
  },
});
