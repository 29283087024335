<template>
  <div id="order-library" class="modal modal-slide-over" data-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <!-- BEGIN: Slide Over Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">Đơn đã lưu</h2>
        </div>
        <!-- END: Slide Over Header -->
        <!-- BEGIN: Slide Over Body -->
        <div class="modal-body">
          <div v-for="order in orderLibrary" :key="order.id" class="mt-3 flex-col">
            <span> {{ order.senderName }}</span>
            <span> {{ order.senderPhone }}</span>
            <span> {{ order.receiverName }}</span>
            <span> {{ order.receiverPhone }}</span>
            <span> {{ order.receiverAddress }}</span>
          </div>
        </div>
        <!-- END: Slide Over Body -->
        <!-- BEGIN: Slide Over Footer -->
        <div class="modal-footer text-right w-full absolute bottom-0">
          <input type="checkbox" class="border" /> Chọn tất cả
          <button type="button" class="button bg-theme-1 text-white w-20">Tạo đơn</button>
        </div>
        <!-- END: Slide Over Footer -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "OrderLibrary",
  setup() {
    const store = useStore();
    const orderLibrary = computed(() => store.state.main.orderLibrary);
    return {
      orderLibrary,
    };
  },
});
</script>
